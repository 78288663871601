import { Injectable } from '@angular/core';
import { Feature } from '../models/feature.model';
import { BehaviorSubject, filter, map, Observable, startWith } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { AppConstants } from 'src/app/app.constants';
import { features } from 'node:process';

@Injectable({
    providedIn: 'root'
})
export class CellerFeaturesService {
    private cellerFeatures = new BehaviorSubject<Feature[]>([]);
    cellerFeatures$: Observable<Feature[]> = this.cellerFeatures.asObservable();

    constructor(
        private localStorageService: LocalStorageService,
    ) {
        if (this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER_FEATURES)) {
            this.cellerFeatures = new BehaviorSubject<Feature[]>(JSON.parse(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER_FEATURES)));
        }
    }

    setAll(cellerFeatures: Feature[]) {
        this.cellerFeatures.next(cellerFeatures);

        // Save in localStorage
        this.localStorageService.setData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER_FEATURES, JSON.stringify(this.cellerFeatures.getValue()));
    }

    hasFeature(featureCode: string): boolean {
        const feature = this.cellerFeatures.getValue().find((feature: Feature) => feature.code == featureCode);
        return (feature) ? true : false;
    }

    reset() {
        this.cellerFeatures.next([]);
    }
}
