import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PreviousRouteService {
    private previousUrl: string | null = null;
    private currentUrl: string | null = null;

    constructor(
        private router: Router
    ) {
        this.currentUrl = this.router.url;

        this.router.events.pipe(
            filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.urlAfterRedirects;
        });
    }

    // Obtener la URL anterior
    public getPreviousUrl(): string | null {
        return this.previousUrl;
    }
}
