import { Component, inject, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { PreviousRouteService } from './shared/services/previous-route.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    // Inject PreviousRouteService to track every previous route on navigation
    public previousRouteService = inject(PreviousRouteService);

    constructor(private primengConfig: PrimeNGConfig, private as: PreviousRouteService) { }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        this.primengConfig.setTranslation({
            today: $localize`Avui`,
            dayNames: [$localize`Diumenge`, $localize`Dilluns`, $localize`Dimarts`, $localize`Dimecres`, $localize`Dijous`, $localize`Divendres`, $localize`Dissabte`],
            dayNamesShort: [$localize`Dg`, $localize`Dl`, $localize`Dm`, $localize`Dc`, $localize`Dj`, $localize`Dv`, $localize`Ds`],
            dayNamesMin: [$localize`Dg`, $localize`Dl`, $localize`Dm`, $localize`Dc`, $localize`Dj`, $localize`Dv`, $localize`Ds`],
            monthNames: [$localize`Gener`, $localize`Febrer`, $localize`Març`, $localize`Abril`, $localize`Maig`, $localize`Juny`, $localize`Juliol`, $localize`Agost`, $localize`Setembre`,
            $localize`Octubre`, $localize`Novembre`, $localize`Desembre`],
            monthNamesShort: [$localize`Gen`, $localize`Feb`, $localize`Mar`, $localize`Abr`, $localize`Mai`, $localize`Jun`, $localize`Jul`, $localize`Ago`, $localize`Set`, $localize`Oct`, $localize`Nov`,
            $localize`Des`],
            clear: $localize`Esborrar`,
            dateFormat: 'dd/mm/yy',
            weekHeader: $localize`Setmana`,
            firstDayOfWeek: 1,
            accept: $localize`Acceptar`,
            reject: $localize`Rebutjar`,
            emptyMessage: $localize`No s'han trobat resultats`,
            emptyFilterMessage: $localize`No s'han trobat resultats`,
        });
    }

}
