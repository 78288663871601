import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DigitalLabelUrl } from '../models/digital-label-url.model';

@Injectable({
    providedIn: 'root'
})
export class DigitalLabelUrlService {
    private serviceUrl = `${environment.apiUrl}/digital_label_url`;

    constructor(
        private httpClient: HttpClient,
    ) { }


    view(idShortUrl: string): Observable<DigitalLabelUrl> {
        return this.httpClient.get<DigitalLabelUrl>(`${this.serviceUrl}/${idShortUrl}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }
}
