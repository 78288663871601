import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { AppConstants } from 'src/app/app.constants';
import { AuthModel } from 'src/app/modules/auth/models/auth.model';
import { catchError } from "rxjs/operators";
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add authorization header with jwt token if available
    if(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_AUTH_TOKEN)){
        const authModel = JSON.parse(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_AUTH_TOKEN)) as AuthModel;
        
        if(!request.url.includes(environment.serviceSoda) && authModel && authModel.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${authModel.token}`
                }
            });
        }
    }
    
    return next.handle(request).pipe(
      catchError((error:any | undefined) => {
        if(error?.code === 401) {
          if(!environment.production) console.log(error);
          this.authService.logout();
          return throwError(() => error);
        }
        
        return throwError(() => error);
      })
    );
  }
}
