import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserModel } from '../../../shared/models/user.model';
import { AuthModel } from '../models/auth.model';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { AppConstants } from 'src/app/app.constants';
import { CellerCae } from 'src/app/shared/models/celler-cae';
import { Celler } from 'src/app/shared/models/celler';
import { Register } from '../models/register.model';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { CellerConfiguration } from '../../configuration/models/celler-configuration';
import { PermissionsService } from 'src/app/shared/services/permissions.service';

export type UserType = UserModel | undefined;
export type CellerType = Celler | undefined;
export type CellerCaeType = CellerCae | undefined;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    // private fields
    private unsubscribe: Subscription[] = [];

    // public fields
    public currentUser$: Observable<UserType>;
    public currentUserSubject: BehaviorSubject<UserType | undefined>;
    public currentCeller$: Observable<CellerType>;
    public currentCellerSubject: BehaviorSubject<CellerType>;
    public currentCellerCae$: Observable<CellerCaeType>;
    public currentCellerCaeSubject: BehaviorSubject<CellerCaeType>;

    get currentUserValue(): UserType {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserType) {
        this.currentUserSubject.next(user);
    }

    get currentCellerValue(): CellerType {
        return this.currentCellerSubject.value;
    }

    set currentCellerValue(celler: CellerType) {
        this.currentCellerSubject.next(celler);
    }

    get currentCellerCaeValue(): CellerCaeType {
        return this.currentCellerCaeSubject.value;
    }

    set currentCellerCaeValue(cellerCae: CellerCaeType) {
        this.currentCellerCaeSubject.next(cellerCae);
    }

    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private localStorageService: LocalStorageService,
        private layoutService: LayoutService,
        private permissionsService: PermissionsService
    ) {
        // Set user
        let user = undefined;
        if (this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_USER)) {
            user = JSON.parse(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_USER)) as UserModel;
        }
        this.currentUserSubject = new BehaviorSubject<UserType>(user);
        this.currentUser$ = this.currentUserSubject.asObservable();

        // Set celler
        let celler = undefined;
        if (this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER)) {
            celler = JSON.parse(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER)) as Celler;
        }
        this.currentCellerSubject = new BehaviorSubject<CellerType>(celler);
        this.currentCeller$ = this.currentCellerSubject.asObservable();

        // Set cellerCae
        let cellerCae = undefined;
        if (this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER_CAE)) {
            cellerCae = JSON.parse(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER_CAE)) as CellerCae;
        }
        this.currentCellerCaeSubject = new BehaviorSubject<CellerCaeType>(cellerCae);
        this.currentCellerCae$ = this.currentCellerCaeSubject.asObservable();
    }

    login(email: string, password: string): Observable<any> {
        const params = { username: email, password: password };

        return this.httpClient.post<any>(`${environment.apiUrl}/login`, params).pipe(
            map((res: any) => {
                return this._setCurrentUserCeller(res);
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    refreshToken(cae: CellerCae) {
        // Set new CellerCae
        this.currentCellerCaeSubject.next(cae);

        const auth = JSON.parse(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_AUTH_TOKEN)) as AuthModel;
        const params = { refresh_token: auth.refreshToken };

        let options = undefined;
        if (this.currentCellerCaeValue) {
            options = { headers: new HttpHeaders({ 'Celler-Cae': this.currentCellerCaeValue.id.toString() }) };
        }

        return this.httpClient.post<any>(`${environment.apiUrl}/token/refresh`, params, options).pipe(
            map((res: any) => {
                return this._setCurrentUserCeller(res);
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    tokenExpired() {
        if (this.localStorageService.getData('authToken')) {
            var token = JSON.parse(this.localStorageService.getData('authToken'));
            const expiry = (JSON.parse(atob(token.token.split('.')[1]))).exp;
            return (Math.floor((new Date).getTime() / 1000)) >= expiry;
        }
        return true;
    }

    logout(url?: string) {
        this.localStorageService.clearData();

        this.currentUserSubject.next(undefined);
        this.currentCellerSubject.next(undefined);
        this.currentCellerCaeSubject.next(undefined);
        this.permissionsService.reset();
        this.layoutService.state.profileSidebarVisible = false;

        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: url } });
    }

    registration(register: Register): Observable<Register> {
        return this.httpClient.post<Register>(`${environment.apiUrl}/register`, register).pipe(
            map((res: any) => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    forgotPassword(email: string): Observable<any> {
        return this.httpClient.post<any>(`${environment.apiUrl}/send_reset_password/${email}`, {}).pipe(
            map((res: any) => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    resetPassword(password: string, token: string): Observable<any> {
        return this.httpClient.post<any>(`${environment.apiUrl}/reset_password/${token}`, { password }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    confirmation(token: string): Observable<any> {
        return this.httpClient.post<any>(`${environment.apiUrl}/celler_confirmation/${token}`, {}).pipe(
            map((res: any) => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    // private methods
    private _setAuthFromLocalStorage(auth: AuthModel): boolean {
        // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
        if (auth && auth.token) {
            this.localStorageService.setData(AppConstants.LOCAL_STORAGE_AUTH_TOKEN, JSON.stringify(auth));
            return true;
        }
        return false;
    }

    private _setCurrentUserCeller(res: any) {
        // Set authentication data
        let auth = new AuthModel();
        auth.token = res.token;
        auth.refreshToken = res.refresh_token;
        this._setAuthFromLocalStorage(auth);

        // TODO: set celler
        if (res.celler) {
            if (!environment.production) console.log(res.celler);
        }

        // TODO: set subscription
        if (res.subscription) {
            if (!environment.production) console.log(res.subscription);
        }

        // Set user
        let user = new UserModel();
        if (res.user) {
            user.id = res.user.idUsuari;
            user.fullname = res.user.nomComplet;
            user.username = res.user.username;
            user.roles = res.user.roles;
            user.email = res.user.email;
            user.pic = './assets/media/avatars/300-1.jpg';
            this.localStorageService.setData(AppConstants.LOCAL_STORAGE_CURRENT_USER, JSON.stringify(user));
            this.currentUserSubject.next(user);
            if (!environment.production) console.log(user);
        }
        else {
            this.logout();
        }

        // Set celler
        let celler = new Celler();
        if (res.celler) {
            celler = res.celler;
            if (res.celler.caes) celler.caes = res.celler.caes as CellerCae[];
            this.localStorageService.setData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER, JSON.stringify(celler));
            this.currentCellerSubject.next(celler);
            if (!environment.production) console.log(celler);
        }

        // Set cellerCae
        let cellerCae = new CellerCae();
        if (res.celler && res.celler.currentCellerCae) {
            cellerCae = res.celler.currentCellerCae as CellerCae;
            this.localStorageService.setData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER_CAE, JSON.stringify(cellerCae));
            this.currentCellerCaeSubject.next(cellerCae);
            if (!environment.production) console.log(cellerCae);
        }


        // Set celler configuration
        let cellerConfiguration = [];
        if (res.celler && res.celler.configuration) {
            for (let configuration of res.celler.configuration) {
                if (configuration.name === 'general_configuration') {
                    const generalConfigurationValues = JSON.parse(configuration.value);
                    for (let keyConfiguration of Object.keys(generalConfigurationValues)) {
                        const config = new CellerConfiguration;
                        config.id = configuration.id;
                        config.name = keyConfiguration;
                        config.value = generalConfigurationValues[keyConfiguration];
                        cellerConfiguration.push(config);
                    }
                }
                else {
                    const config = new CellerConfiguration;
                    config.id = configuration.id;
                    config.name = configuration.name;
                    config.value = JSON.parse(configuration.value);
                    cellerConfiguration.push(config)
                }
            }

            this.permissionsService.setAll(cellerConfiguration);
            if (!environment.production) console.log(cellerConfiguration);
        }
        else {
            this.permissionsService.reset();
        }

        return user;
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
