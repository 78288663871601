<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="sidebar-header pb-3">
        <a [routerLink]="['/harvest/receive-grape']" class="app-logo">
            <img src="./assets/media/evins.png" height="30px">
        </a>
        <button class="layout-sidebar-anchor p-link z-2 mb-2" type="button" (click)="anchor()"></button>
    </div>
 

    <div #menuContainer class="layout-menu-container">
        <app-menu></app-menu>
    </div>
    <app-message-update></app-message-update>
    <button pButton pRipple label="Centre de suport" class="mb-2 ml-4 mr-4" (click)="goToSupport()" i18n></button>
    <p class="text-sm pb-2 pl-5">{{ version }}</p>
    
</div>