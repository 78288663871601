import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, shareReplay, tap } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class ApiCacheInterceptor implements HttpInterceptor {
    // Cache to store response data
    private cache = new Map<string, Observable<HttpEvent<any>>>();

    // Set of endpoints to cache
    private endpointsToCache = new Set([
        '/aptituds?qualificar=false',
        '/aptituds?qualificar=false&exists[regulation]=true',
        '/aptituds?pagination=false',
        '/aptitud_subdivisios',
        '/zona_cava',
        '/subzona_cava'
    ]);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = request.url.replace(environment.apiUrl, '');

        // Check if the request endpoint is in the set of endpoints to cache
        if (this.endpointsToCache.has(url)) {
            // Check if the request is already in the cache (either in-flight or completed)
            const cachedResponse = this.cache.get(request.url);

            if (cachedResponse) {
                return cachedResponse;
            }

            // If the request is not in the cache, send it and cache the response
            const requestHandle = next.handle(request).pipe(
                tap(response => {
                    if (response instanceof HttpResponse) {
                        this.cache.set(request.url, of(response));
                    }
                }),
                shareReplay(1) // Ensure all subscribers share the same observable
            );

            this.cache.set(request.url, requestHandle);

            return requestHandle;
        }

        return next.handle(request);
    }
}