import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DigitalLabelUrlService } from '../../sevices/digital-label-url.service';
import { first } from 'rxjs';
import { DigitalLabelUrl } from '../../models/digital-label-url.model';

@Component({
    selector: 'app-digital-label-redirect',
    templateUrl: './digital-label-redirect.page.html',
    styleUrl: './digital-label-redirect.page.scss',
    standalone: true
})
export class DigitalLabelRedirectPage {
    public shortUrlId: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private digitalLabelUrlService: DigitalLabelUrlService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.shortUrlId = this.activatedRoute.snapshot.params['shortUrlId'];
        
        if(this.shortUrlId){
            this.digitalLabelUrlService.view(this.shortUrlId).pipe(first()).subscribe({
                next: (digitalLabelUrl: DigitalLabelUrl) => {
                    if (digitalLabelUrl?.url) {
                        window.location.href = digitalLabelUrl.url;
                    }
                }
            });
        }
    }
}
