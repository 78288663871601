import { CellerConfiguration } from "src/app/modules/configuration/models/celler-configuration";
import { CellerCae } from "./celler-cae";
import { UserModel } from "./user.model";
import { FormControl } from "@angular/forms";

/**************************
***** MODEL DEFINITION ****
***************************/
export class Celler {
    public '@id': string;
    public idCeller: number;
    public caes: CellerCae[];
    public cellerConfiguration: CellerConfiguration[];
    public idUsuari: UserModel;
    public nif: number;
    public nom: string;
    public reovi: number;
    public nidpb: number;
    public ria: number;
    public dataCreacio: Date;
    public gran: boolean;
    public responsable: string;
}


/*****************************
***** FORMGROUP INTERFACE ****
******************************/
export interface CellerForm {
    nom: FormControl<string | null>;
    nif: FormControl<string | null>;
    email: FormControl<string | null>;
    password: FormControl<string | null>;
    re_password: FormControl<string | null>;
    idioma: FormControl<string | null>;
}
