<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-profile-sidebar w-full sm:w-25rem">
    <div class="flex flex-column mx-auto md:mx-0">
        <span class="mb-2 font-semibold" i18n>Benvingut/da</span>
        <span class="text-color-secondary font-medium mb-1">{{ currentUser?.fullname }}</span>
        <span class="text-color-secondary font-medium mb-5">{{ currentUser?.email }}</span>

        <ul class="list-none m-0 p-0">
            <li>
                <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-user text-xl text-primary"></i>
                    </span>
                    <div class="ml-3">
                        <span class="mb-2 font-semibold" i18n>Perfil d'usuari</span>
                        <p class="text-color-secondary m-0" i18n></p>
                    </div>
                </a>
            </li>
            @if(currentUser?.roles!.includes('ROLE_SUPER_ADMIN') || currentUser?.roles!.includes('ROLE_ADMIN')) {
                <li (click)="configuration()">
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-cog text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold" i18n>Configuració</span>
                            <p class="text-color-secondary m-0" i18n></p>
                        </div>
                    </a>
                </li>
            }
            <li (click)="logout()">
                <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-power-off text-xl text-primary"></i>
                    </span>
                    <div class="ml-3">
                        <span class="mb-2 font-semibold" i18n>Tancar sessió</span>
                        <p class="text-color-secondary m-0" i18n></p>
                    </div>
                </a>
            </li>
        </ul>
    </div>    
</p-sidebar>