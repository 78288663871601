import { UserDetail } from "./user-detail.model";

export class UserModel {
    public '@id': string;
    public idUsuariDetall: UserDetail;
    public idUsuari: number;
    public id: number;
    public username: string;
    public password: string;
    public fullname: string;
    public email: string;
    public pic: string;
    public roles: string[] = [];
    public occupation: string;
    public companyName: string;
    public phone: string;
    public dataCreacio: Date;

    // personal information
    public firstname: string;
    public lastname: string;
    public website: string;

    // account information
    public language: string;
    public timeZone: string;
    public communication: {
        email: boolean;
        sms: boolean;
        phone: boolean;
    };


    setUser(_user: unknown) {
        const user = _user as UserModel;
        this.id = user.id;
        this.idUsuari = user.idUsuari;
        this.username = user.username || '';
        this.password = user.password || '';
        this.fullname = user.fullname || '';
        this.email = user.email || '';
        this.pic = user.pic || './assets/media/avatars/blank.png';
        this.roles = user.roles || [];
        this.occupation = user.occupation || '';
        this.companyName = user.companyName || '';
        this.phone = user.phone || '';
    }
}

export enum UserPermission {
    appccManagement = 'appccManagement',
    dryGoodsManagement = 'dryGoodsManagement',
    ervc_credentials = 'ervc_credentials',
    factusol_credentials = 'factusol_credentials',
    holded_credentials = 'holded_credentials',
    vesselsManagement = 'vesselsManagement',
    vineyardAdvancedMode = 'vineyardAdvancedMode',
    vineyardAnalysisMode = 'vineyardAnalysisMode',
    receiveTrailerMode = 'receiveTrailerMode',
    language = 'language'
}
