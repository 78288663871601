import { FormControl } from "@angular/forms";
import { OficinaComarcal } from "./oficina-comarcal.model";
import { Provincia } from "./provincia.model";

export class CellerCae {
    public '@id': string;
    public id: number;
    public cae: string;
    public idActivitat: any;
    public codiActivitat: string;
    public descripcioActivitat: string;
    public direccio: string;
    public idProvincia: Provincia;
    public poblacio: string;
    public codiPostal: string;
    public idOficina: OficinaComarcal;
    public isDeleteable: boolean;
    public numAutoritzacio: string;
}

export interface CellerCaeForm {
    id?: FormControl<string | number | null>;
    cae: FormControl<string | null>;
    idActivitat: FormControl<string | null>;
    direccio: FormControl<string | null>;
    idProvincia: FormControl<string | null>;
    poblacio: FormControl<string | null>;
    codiPostal: FormControl<string | null>;
    idOficina: FormControl<string | null>;
    numAutoritzacio: FormControl<string | null>;
}