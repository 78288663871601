export class AuthModel {
    
    public token: string;
    public refreshToken: string;
    public expiresIn: Date;

    setAuth(auth: AuthModel) {
        this.token = auth.token;
        this.refreshToken = auth.refreshToken;
        this.expiresIn = auth.expiresIn;
    }

}
