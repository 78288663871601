import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiCollectionResponse } from 'src/app/shared/models/api-collection-response.model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, catchError, map, throwError } from 'rxjs';
import { DigitalLabel } from '../models/digital-label.model';

@Injectable({
    providedIn: 'root'
})
export class DigitalLabelService {
    private serviceUrl = `${environment.apiUrl}/digital_label`;

    private pdfSource = new Subject();
    pdfSource$ = this.pdfSource.asObservable();

    constructor(
        private httpClient: HttpClient,
    ) { }

    pdf() {
        this.pdfSource.next(true);
    }

    index(query?: string): Observable<ApiCollectionResponse<DigitalLabel>> {
        const queryParams = (query) ? `?${query}` : '';
        return this.httpClient.get<ApiCollectionResponse<DigitalLabel>>(`${this.serviceUrl}${queryParams}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    create(params: any): Observable<DigitalLabel> {
        return this.httpClient.post<DigitalLabel>(`${this.serviceUrl}`, params).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    get(id: number): Observable<DigitalLabel> {
        return this.httpClient.get<DigitalLabel>(`${this.serviceUrl}/${id}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    view(shortUrlId: string, query?: string): Observable<DigitalLabel> {
        const queryParams = (query) ? `?${query}` : '';
        return this.httpClient.get<DigitalLabel>(`${this.serviceUrl}/view/${shortUrlId}${queryParams}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    put(id: number, params: any): Observable<DigitalLabel> {
        return this.httpClient.put<DigitalLabel>(`${this.serviceUrl}/${id}`, params).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    delete(id: number): Observable<DigitalLabel> {
        return this.httpClient.delete<DigitalLabel>(`${this.serviceUrl}/${id}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }
}
