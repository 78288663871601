export class AppConstants {

    // Local storage variables
    public static LOCAL_STORAGE_LOCALIZATION_LOCALE = 'language';
    public static LOCAL_STORAGE_AUTH_TOKEN = 'authToken';
    public static LOCAL_STORAGE_CURRENT_USER = 'currentUser';
    public static LOCAL_STORAGE_CURRENT_CELLER = 'currentCeller';
    public static LOCAL_STORAGE_CURRENT_CELLER_CAE = 'currentCellerCae';
    public static LOCAL_STORAGE_CURRENT_CELLER_CONFIGURATION = 'currentCellerConfiguration';

    // General constants
    public static ITEMS_PER_PAGE = 10;

    // Max kilos for a parcel
    public static MAX_KILOS_BLANC = 20000;
    public static MAX_KILOS_NEGRE = 18000;
}