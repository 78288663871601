import { CellerCae } from "./celler-cae";

export class Celler {
    public idCeller: number;
    public nomcae: string;
    public nif: number;
    public reovi: number;
    public nidpb: number;
    public ria: number;
    public dataCreacio: Date;
    public gran: boolean;
    public caes: CellerCae[];
    public nom: string;
}
