export class ApiCollectionResponse<T> {

    public totalItems:number;
    public firstPage: number | null;
    public lastPage: number | null;
    public previousPage: number | null;
    public nextPage: number | null;
    public items:Array<T> = [];

}
