import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, first, map, of, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Aptitud } from '../models/aptitud';
import { ApiCollectionResponse } from '../models/api-collection-response.model';

@Injectable({
    providedIn: 'root'
})
export class AptitudsService {

    private serviceUrl = `${environment.apiUrl}/aptituds`

    constructor(
        private httpClient: HttpClient,
    ) { }

    index(query?: string): Observable<ApiCollectionResponse<Aptitud>> {
        const queryParams = (query) ? `?${query}` : '';
        return this.httpClient.get<ApiCollectionResponse<Aptitud>>(`${this.serviceUrl}${queryParams}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    indexByCeller(query?: string): Observable<ApiCollectionResponse<Aptitud>> {
        const queryParams = (query) ? `?${query}` : '';
        return this.httpClient.get<ApiCollectionResponse<Aptitud>>(`${this.serviceUrl}/celler${queryParams}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    view(id: number): Observable<Aptitud> {
        return this.httpClient.get<Aptitud>(`${this.serviceUrl}/${id}`).pipe(
            map(res => {
                return res;
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    getMaxExtractionYield(idAptitud: number, totalKilos: number, colorCode: string): Observable<number | null> {
        return this.view(idAptitud).pipe(
            first(),
            switchMap((aptitud: Aptitud) => {
                if (!aptitud) return of(null);

                const maxExtractionYield = (colorCode == 'BLA') ? aptitud.rendimentBlanc : aptitud.rendimentNegre;
                const maxLitres = Math.round(totalKilos * (maxExtractionYield / 100));
                return of(maxLitres);
            })
        );
    }
}
