<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>

        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu">
            <li *ngIf="user?.roles?.includes('ROLE_SUPER_ADMIN')" class="topbar-celler-selector mr-4">
                <p-dropdown [options]="cellers" optionLabel="nom" optionValue="idCeller" [filter]="true" filterBy="nom"
                    [(ngModel)]="idCeller" (onChange)="changeCeller($event.value)" appendTo="body"
                ></p-dropdown>
            </li>
            <li class="topbar-cae-selector">
                <p-dropdown [options]="caes" optionLabel="cae" optionValue="id" [(ngModel)]="idCae" (onChange)="changeCae($event.value)"></p-dropdown>
            </li>
            <li class="topbar-profile">
                <button type="button" class="p-link" (click)="onProfileButtonClick()">
                    <img src="assets/media/avatars/blank.png" alt="Perfil d'usuari" style="border-radius: 50%;"/>
                </button>
            </li>
        </ul>
    </div>
</div>