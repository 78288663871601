<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-profile-sidebar w-full sm:w-30rem">
    <div class="flex flex-column mx-auto md:mx-0">
        <span class="mb-2 font-semibold" i18n>Benvingut/da</span>
        <span class="text-color-secondary font-medium mb-1">{{ currentUser?.fullname }}</span>
        <span class="text-color-secondary font-medium mb-5">{{ currentUser?.email }}</span>

        <p-accordion class="w-full" class="menu-accordion" styleClass="mb-0">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="flex align-items-start">
                        <i class="pi pi-user text-xl text-primary pt-1"></i>
                        <div class="flex flex-column pl-3">
                            <span class="font-semibold white-space-nowrap" i18n>Compte</span>
                            <span class="text-color-secondary font-normal m-0 pt-1">Personalitza la teva compta, registra els dipòsits fiscals, configura les teves instal·lacions i afegeix nous usuaris.</span>
                        </div>
                    </div>
                </ng-template>
                <p-button class="block" label="Configuració del compte" [link]="true" (click)="goTo('/settings/account/preferences')" i18n-label/>
                <p-button class="block" label="Varietats" [link]="true" (click)="goTo('/settings/account/varieties')" i18n-label/>
                <p-button class="block" label="Dipòsits fiscals" [link]="true" (click)="goTo('settings/account/tax-warehouse')" i18n-label/>
                <p-button class="block" label="Instal·lacions" [link]="true" (click)="goTo('settings/account/facility')" i18n-label/>
                <p-button class="block" label="Usuaris" [link]="true" (click)="goTo('settings/account/user')" i18n-label/>
            </p-accordionTab>
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="flex align-items-start">
                        <i class="pi fa-solid fa-cubes text-xl text-primary pt-1"></i>
                        <div class="flex flex-column pl-3">
                            <span class="font-semibold white-space-nowrap" i18n>Mòduls</span>
                            <span class="text-color-secondary font-normal m-0 pt-1">Configura eVins de la manera que més t'agradi amb l'ajustament dels diferents mòduls.</span>
                        </div>
                    </div>
                </ng-template>
                <p-button class="block" label="Verema" [link]="true" (click)="goTo('settings/module/harvest')" i18n-label/>
                <p-button class="block" label="Dipòsits" [link]="true" (click)="goTo('settings/module/vessel')" i18n-label/>
                <p-button class="block" label="Inventaris" [link]="true" (click)="goTo('settings/module/product')" i18n-label/>
                <p-button class="block" label="APPCC" [link]="true" (click)="goTo('settings/module/appcc')" i18n-label/>
                <p-button class="block" label="Informes" [link]="true" (click)="goTo('settings/module/report')" i18n-label/>
            </p-accordionTab>
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="flex align-items-start">
                        <i class="pi fa-solid fa-link text-xl text-primary pt-1"></i>
                        <div class="flex flex-column pl-3">
                            <span class="font-semibold white-space-nowrap" i18n>Integracions</span>
                            <span class="text-color-secondary font-normal m-0 pt-1">Sincronitza els clients, els estocs, les vendes i molt més amb Holded o Factusol.</span>
                        </div>
                    </div>
                </ng-template>
                <p-button class="block" label="Factusol" [link]="true" (click)="goTo('settings/integration/factusol')" i18n-label/>
                <p-button class="block" label="Holded" [link]="true" (click)="goTo('settings/integration/holded')" i18n-label/>
            </p-accordionTab>
        </p-accordion>

        @if(currentUser?.roles!.includes('ROLE_SUPER_ADMIN')) {
            <a (click)="goTo('settings/logs')" class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150" style="background-color: #f9fafb;">
                <span>
                    <i class="pi pi-cog text-xl text-primary"></i>
                </span>
                <div class="ml-3">
                    <span class="mb-2 font-semibold" i18n>Logs</span>
                    <p class="text-color-secondary m-0" i18n></p>
                </div>
            </a>
        }

        <div class="">
            <a (click)="logout()" class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150" style="background-color: #f9fafb;">
                <span>
                    <i class="pi pi-power-off text-xl text-primary"></i>
                </span>
                <div class="ml-3">
                    <span class="mb-2 font-semibold" i18n>Tancar sessió</span>
                    <p class="text-color-secondary m-0" i18n></p>
                </div>
            </a>
        </div>


        <!-- <ul class="list-none m-0 p-0">
            <li>
                <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-user text-xl text-primary"></i>
                    </span>
                    <div class="ml-3">
                        <span class="mb-2 font-semibold" i18n>Perfil d'usuari</span>
                        <p class="text-color-secondary m-0" i18n>Lorem ipsum date visale</p>
                    </div>
                </a>
            </li>
            @if(currentUser?.roles!.includes('ROLE_SUPER_ADMIN') || currentUser?.roles!.includes('ROLE_ADMIN')) {
                <li (click)="configuration()">
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-cog text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold" i18n>Configuració</span>
                            <p class="text-color-secondary m-0" i18n></p>
                        </div>
                    </a>
                </li>
            }
            <li (click)="logout()">
                <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                    <span>
                        <i class="pi pi-power-off text-xl text-primary"></i>
                    </span>
                    <div class="ml-3">
                        <span class="mb-2 font-semibold" i18n>Tancar sessió</span>
                        <p class="text-color-secondary m-0" i18n></p>
                    </div>
                </a>
            </li>
        </ul> -->
    </div>    
</p-sidebar>