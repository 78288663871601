import { Component } from '@angular/core';
import { DigitalLabelService } from '../../sevices/digital-label.service';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { DigitalLabel } from '../../models/digital-label.model';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { Language } from 'src/app/shared/models/language';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { UserModel } from 'src/app/shared/models/user.model';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { AppConstants } from 'src/app/app.constants';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';


@Component({
    selector: 'app-digital-label-view',
    templateUrl: './digital-label-view.page.html',
    styleUrl: './digital-label-view.page.scss',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, FormsModule, TableModule, DropdownModule, ProgressSpinnerModule]
})
export class DigitalLabelViewPage {
    public digitalLabelId: string;
    public digitalLabel: DigitalLabel;
    public ingredients: string;
    ingredientsList: string[] = [];
    public languages: Language[] = [];
    public user: UserModel;
    public languageForm: UntypedFormGroup;
    public showLoading: boolean = false;
    
    constructor(
        private activatedRoute: ActivatedRoute,
        private digitalLabelService: DigitalLabelService,
        private translationService: TranslationService,
        private localStorageService: LocalStorageService,
        private formBuilder: FormBuilder,
    ) {
        this.languageForm = this.formBuilder.group({
            language: new FormControl<Language | null>(null),
        });
    }

    ngOnInit(): void {
            this.languageForm.get('language')?.valueChanges.subscribe(lang => {
                this.changeLang(lang);
            });
            this.translationService.index().pipe(first()).subscribe({
                next: (languages: Language[]) => {
                    this.languages = languages;
                    if (this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_USER)) {
                        this.user = JSON.parse(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_USER)) as UserModel;
                        this.languageForm.patchValue({
                            language: this.user.language
                        });
                    }
                }
            });
            this.digitalLabelId = this.activatedRoute.snapshot.params['shortUrlId'];
            
            if(this.digitalLabelId){
                this.digitalLabelService.view(this.digitalLabelId).pipe(first()).subscribe({
                    next: (digitalLabel: DigitalLabel) => {
                        this.digitalLabel = digitalLabel;
                        let ingredients = digitalLabel.ingredients.map(ing => ing.idIngredient.name.toLowerCase());
                        if (ingredients.length > 0) {
                            ingredients[0] = ingredients[0].charAt(0).toUpperCase() + ingredients[0].slice(1);
                        }
                        this.ingredientsList = ingredients;
                    }
                });
            }
    }

    changeLang(lang: string) {
        if(this.digitalLabelId){
            this.showLoading = true;
            this.digitalLabelService.view(this.digitalLabelId, `lang=${lang}`).pipe(first()).subscribe({
                next: (digitalLabel: DigitalLabel) => {
                    this.digitalLabel = digitalLabel;
                    let ingredients = digitalLabel.ingredients.map(ing => ing.idIngredient.name.toLowerCase());
                    if (ingredients.length > 0) {
                        ingredients[0] = ingredients[0].charAt(0).toUpperCase() + ingredients[0].slice(1);
                    }
                    this.ingredientsList = ingredients;
                    this.showLoading = false;
                }
            });
        }
    }
}
