import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PendingRequestService {
    private pendingRequests = new BehaviorSubject<number>(0);

    constructor() { }

    get pendingRequests$(): Observable<number> {
        return this.pendingRequests.asObservable();
    }

    increment() {
        this.pendingRequests.next(this.pendingRequests.value + 1);
    }

    decrement() {
        this.pendingRequests.next(Math.max(this.pendingRequests.value - 1, 0));
    }
}
