import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AuthService, UserType } from '../modules/auth/services/auth.service';
import { CellerCae } from '../shared/models/celler-cae';
import { first } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { UserModel } from '../shared/models/user.model';
import { CellersService } from '../shared/services/cellers.service';
import { ApiCollectionResponse } from '../shared/models/api-collection-response.model';
import { Celler } from '../shared/models/celler.model';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent implements OnInit {
    public celler: Celler | undefined = this.authService.currentCellerValue;
    public cellers: Celler[] = [];
    public cellerCae: CellerCae | undefined = this.authService.currentCellerCaeValue;
    public caes: any[] = this.celler?.caes as any[] || [];

    public idCae: number | null = this.cellerCae ? this.cellerCae.id : (this.caes.length > 0 ? this.caes[0].id : null);
    public idCeller: number | null = this.celler ? this.celler.idCeller : null;

    public user: UserType = this.authService.currentUserValue;

    @ViewChild('menubutton') menuButton!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        private authService: AuthService,
        private cellersService: CellersService,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        // Get cellers list
        this.cellersService.index().pipe(first()).subscribe((cellers: ApiCollectionResponse<Celler>) => {
            this.cellers = cellers.items;
        });
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    changeCeller(idCeller: number) {
        const celler = this.cellers.find((celler) => celler.idCeller === idCeller);

        if (celler) {
            this.idCeller = celler.idCeller;
            this.authService.currentCellerValue = celler;
            this.caes = celler.caes;
            this.changeCae(this.caes[0].id);
        }
    }

    changeCae(idCae: number) {
        const cae = this.caes.find((cae) => cae.id === idCae);

        if (cae) {
            this.authService.refreshToken(cae).pipe(first()).subscribe({
                next: (user: UserModel | undefined) => {
                    if (!environment.production) console.log(user);
                    if (user) window.location.reload();
                },
                error: (error) => {
                    if (!environment.production) console.log(error);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
                }
            });
        }
    }
}