
<div class="flex justify-content-end p-3">
    <form [formGroup]="languageForm">
        <p-dropdown 
            formControlName="language" 
            [options]="languages" 
            optionLabel="name" optionValue="code"
            [filter]="true" filterBy="name" 
            placeholder="Selecciona idioma"
        ></p-dropdown>
    </form>
</div>
<div class="grid justify-content-center">
    <div class="col-12 md:col-8 lg:col-6">
        <div class="card digital-label-container">
            <div *ngIf="digitalLabel" class="container">
                <div class="header">
                    <!-- <img src="" alt="Logo" class="logo"> -->
                    <h2>{{ digitalLabel.name }}</h2>
                </div>
            
                <p-table class="evins-label-table evins-top-table" [value]="[ingredientsList.join(', ')]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{ digitalLabel.ingredientsHeader }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-ingredients>
                        <tr>
                            <td>{{ ingredients }}</td>
                        </tr>
                    </ng-template>
                </p-table>                
            
                <p-table class="evins-label-table" [value]="digitalLabel.nutritionalValues" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{ digitalLabel.nutritionalValuesHeader }}</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-value>
                        <tr>
                            <td>{{ value.idField.name }}</td>
                            <td>{{ value.quantitat }} {{ value.idField.units }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            
                <p-table class="evins-label-table" [value]="[digitalLabel]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{ digitalLabel.otherInfoHeader }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data>
                        <tr>
                            <td><strong>{{ digitalLabel.alcohol }}:</strong> {{ data.grau }}%</td>
                        </tr>
                    </ng-template>
                </p-table>
                            
                <div class="footer">
                    <p>© eVins</p>
                </div>
            </div>
        </div>
    </div>
    <div class="w-12 text-center evins-progress-spinner" *ngIf="showLoading">
        <p-progressSpinner styleClass="evins-spinner" strokeWidth="3"></p-progressSpinner>
    </div>
</div>
