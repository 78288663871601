import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { WebServiceWorkerService } from '../../services/web-service-worker.service';
import confetti from 'canvas-confetti';

@Component({
    selector: 'app-message-update',
    templateUrl: './message-update.component.html',
    styleUrl: './message-update.component.scss'
})
export class MessageUpdateComponent {

    public isNewAppVersionAvailable: boolean = false;
    public newAppUpdateAvailableSubscription: Subscription;

    constructor(
        private webServiceWorker: WebServiceWorkerService,
    ) { }

    ngOnInit(): void {
        this.checkIfAppUpdated();
    }

    checkIfAppUpdated() {
        this.newAppUpdateAvailableSubscription = this.webServiceWorker.$isAnyNewUpdateAvailable.subscribe((versionAvailableFlag: boolean) => {
            this.isNewAppVersionAvailable = versionAvailableFlag;
        });
    }

    refreshApp() {
        this.showConffeti().then(result => {
            window.location.reload();
        });
    }

    showConffeti() {
        const duration = 2500;

        confetti({
            particleCount: 380,
            spread: 160,
            origin: { y: 0.6 },
            zIndex: 2000,
        });

        return new Promise(resolve => {
            setTimeout(() => {
                confetti.reset();
                resolve('Confetti cleared');
            }, duration);
        });
    }

    ngOnDestroy() {
        this.newAppUpdateAvailableSubscription?.unsubscribe();
    }

}
