import { Component, OnInit } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthService, UserType } from '../modules/auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent implements OnInit {

    public currentUser: UserType = this.authService.currentUserValue

    constructor(
        public layoutService: LayoutService,
        public authService: AuthService,
        public router: Router,
    ) { }

    ngOnInit() {
        this.currentUser = this.authService.currentUserValue;
    }

    configuration() {
        this.layoutService.state.profileSidebarVisible = false;
        this.router.navigate(['/configuration']);
    }

    logout() {
        this.authService.logout();
    }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }
}