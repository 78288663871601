import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(private primengConfig: PrimeNGConfig) { }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
        this.primengConfig.setTranslation({
            today: 'Avui',
            dayNames: ['Diumenge', 'Dilluns', 'Dimarts', 'Dimecres', 'Dijous', 'Divendres', 'Dissabte'],
            dayNamesShort: ['Dg', 'Dl', 'Dm', 'Dc', 'Dj', 'Dv', 'Ds'],
            dayNamesMin: ['Dg', 'Dl', 'Dm', 'Dc', 'Dj', 'Dv', 'Ds'],
            monthNames: ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre',
                'Octubre', 'Novembre', 'Desembre'],
            monthNamesShort: ['Gen', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov',
                'Des'],
            clear: 'Esborrar',
            dateFormat: 'dd/mm/yy',
            weekHeader: 'Setmana',
            firstDayOfWeek: 1,
            accept: 'Acceptar',
            reject: 'Rebutjar',
            emptyMessage: 'No s\'han trobat resultats',
            emptyFilterMessage: $localize`No s\'han trobat resultats`,
        });
    }

}
