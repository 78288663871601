import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './core/guards/auth.guard'
import { AgencyGuard } from './core/guards/agency.guard';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            { path: 'dashboard', data: { breadcrumb: $localize`Inici` }, loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule), canActivate: [AgencyGuard] },
            { path: 'contacts', data: { breadcrumb: $localize`Contactes` }, loadChildren: () => import('./modules/contacts/contacts.module').then((m) => m.ContactsModule), canActivate: [AgencyGuard] },
            { path: 'vineyard', data: { breadcrumb: $localize`Vinya` }, loadChildren: () => import('./modules/vineyard/vineyard.module').then((m) => m.VineyardModule), canActivate: [AgencyGuard] },
            { path: 'harvest', data: { breadcrumb: $localize`Verema` }, loadChildren: () => import('./modules/harvest/harvest.module').then((m) => m.HarvestModule), canActivate: [AgencyGuard] },
            { path: 'vessel', data: { breadcrumb: $localize`Dipòsits` }, loadChildren: () => import('./modules/vessel/vessel.module').then((m) => m.VesselModule), canActivate: [AgencyGuard] },
            { path: 'configuration', data: { breadcrumb: $localize`Configuració` }, loadChildren: () => import('./modules/configuration/configuration.module').then((m) => m.ConfigurationModule), canActivate: [AgencyGuard] },
            { path: 'appcc/cleaning', data: { breadcrumb: $localize`APPCC Neteja` }, loadChildren: () => import('./modules/appcc-cleaning/appcc-cleaning.module').then((m) => m.AppccCleaningModule), canActivate: [AgencyGuard] },
            { path: 'appcc/bottling', data: { breadcrumb: $localize`APPCC Embotellats` }, loadChildren: () => import('./modules/appcc-bottling/appcc-bottling.module').then((m) => m.AppccBottlingModule), canActivate: [AgencyGuard] },
            { path: 'appcc/maintenance', data: { breadcrumb: $localize`APPCC Manteniment` }, loadChildren: () => import('./modules/appcc-maintenance/appcc-maintenance.module').then((m) => m.AppccMaintenanceModule), canActivate: [AgencyGuard] },
            { path: 'beta', data: { breadcrumb: $localize`Informes` }, loadChildren: () => import('./modules/beta/beta.module').then((m) => m.BetaModule), canActivate: [AgencyGuard] },
            { path: 'inventory', data: { breadcrumb: $localize`Estoc` }, loadChildren: () => import('./modules/inventory/inventory.module').then((m) => m.InventoryModule), canActivate: [AgencyGuard] },
            { path: 'product', data: { breadcrumb: $localize`Producte` }, loadChildren: () => import('./modules/product/product.module').then((m) => m.ProductModule), canActivate: [AgencyGuard] },
            { path: 'bottled', data: { breadcrumb: $localize`Envasat` }, loadChildren: () => import('./modules/bottled/bottled.module').then((m) => m.BottledModule), canActivate: [AgencyGuard] },
            { path: 'report', data: { breadcrumb: $localize`Informes` }, loadChildren: () => import('./modules/report/report.module').then((m) => m.ReportModule), canActivate: [AgencyGuard] },
            { path: 'winemaking', data: { breadcrumb: $localize`Elaboració` }, loadChildren: () => import('./modules/winemaking/winemaking.module').then((m) => m.WinemakingModule), canActivate: [AgencyGuard] },
            { path: 'sale', data: { breadcrumb: $localize`Vendes` }, loadChildren: () => import('./modules/sale/sale.module').then((m) => m.SaleModule), canActivate: [AgencyGuard] },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'auth',
        data: { breadcrumb: 'Auth' },
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    },
    { path: 'notfound', loadChildren: () => import('./modules/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
