import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private messageService: MessageService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const excludedUrlPattern = /\/audit\/traceability\/[^/]+$|\/holded_sync_registry|\/factusol_sync_registry$/;
        if (excludedUrlPattern.test(req.url)) {
            return next.handle(req);
        }
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMessage = $localize`Error`;

                if (error.error instanceof Blob) {
                    return new Observable<HttpEvent<any>>(observer => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            try {
                                const errorData = JSON.parse(reader.result as string);
                                if (errorData.detail) errorMessage = errorData.detail;
                                else if (errorData['hydra:description']) errorMessage = errorData['hydra:description'];
                                else errorMessage = $localize`No s'ha pogut generar el document`;
                            } catch (e) {
                                errorMessage = $localize`No s'ha pogut generar el document`;
                            }
                            if (!environment.production) console.log(error);
                            this.showError(errorMessage);
                            observer.error(error);
                        };
                        reader.readAsText(error.error);
                    });
                }
                else if (error.error && error.error['hydra:description']) errorMessage = error.error['hydra:description'];
                else if (error.error && error.error.detail) errorMessage = error.error.detail;
                else if (error.error && error.message) errorMessage = error.error.message;

                if (!environment.production) console.log(error);
                this.showError(errorMessage);
                return throwError(() => error);
            })
        );
    }

    private showError(message: string) {
        this.messageService.add({
            key: 'tst',
            severity: 'error',
            summary: 'Error',
            detail: message
        });
    }
}
