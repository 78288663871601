import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { CellerConfiguration } from 'src/app/modules/configuration/models/celler-configuration';
import { LocalStorageService } from './local-storage.service';
import { AppConstants } from 'src/app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    private cellerConfiguration = new BehaviorSubject<CellerConfiguration[]>([]);
    private cellerConfigurationOptions = new BehaviorSubject<any>({});
    cellerConfiguration$: Observable<CellerConfiguration[]> = this.cellerConfiguration.asObservable();
    cellerConfigurationOptions$: Observable<any> = this.cellerConfigurationOptions.asObservable();

    constructor(
        private localStorageService: LocalStorageService,
    ) {
        if (this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER_CONFIGURATION)) {
            this.cellerConfiguration = new BehaviorSubject<CellerConfiguration[]>(JSON.parse(this.localStorageService.getData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER_CONFIGURATION)));

            let configOptions = this._parseCellerConfigurationToOptions(this.cellerConfiguration.getValue());
            this.cellerConfigurationOptions = new BehaviorSubject(configOptions);
        }
    }

    setAll(cellerConfigurations: CellerConfiguration[]) {
        this.cellerConfiguration.next(cellerConfigurations);

        // Save all options
        let configOptions = this._parseCellerConfigurationToOptions(cellerConfigurations);
        this.cellerConfigurationOptions.next(configOptions);

        // Save in localStorage
        this.localStorageService.setData(AppConstants.LOCAL_STORAGE_CURRENT_CELLER_CONFIGURATION, JSON.stringify(this.cellerConfiguration.getValue()));
    }

    setOption(optionName: string, optionValue: any) {
        const found = this.cellerConfiguration.getValue().find((cellerConfiguration: CellerConfiguration) => cellerConfiguration.name === optionName);
        if (found) {
            found.value = optionValue
        }
        else {
            // Create new configuration
            const cellerConfiguration = new CellerConfiguration;
            cellerConfiguration.name = optionName;
            cellerConfiguration.value = optionValue;

            // Push new configuration to cellerConfiguration
            let configs = this.cellerConfiguration.getValue();
            configs.push(cellerConfiguration);
            this.cellerConfiguration.next(configs);
        }

        this.setAll(this.cellerConfiguration.getValue());
    }

    getAllOptions(): Observable<any> {
        return this.cellerConfigurationOptions.asObservable();
    }

    getOptionValue(userPermission: string): Observable<any> {
        return this.cellerConfigurationOptions.asObservable().pipe(
            map(options => {
                const optionName = Object.keys(options).find(option => option == userPermission);
                return optionName ? options[optionName] : null;
            })
        );
    }

    reset() {
        this.cellerConfiguration.next([]);
        this.cellerConfigurationOptions.next({});
    }

    private _parseCellerConfigurationToOptions(cellerConfigurations: CellerConfiguration[]) {
        let configOptions: any = {};
        for (let cellerConfiguration of cellerConfigurations) {
            configOptions[cellerConfiguration.name] = cellerConfiguration.value;
        }
        return configOptions;
    }
}
