import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebServiceWorkerService {

    $isAnyNewUpdateAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
    serviceSubscriptions: Subscription[] = [];

    constructor(public swUpdate: SwUpdate) {
        this.initialize();
    }

    initialize() {
        console.log('Service worker enabled:', this.swUpdate.isEnabled);
        if (this.swUpdate.isEnabled) {
            if(!environment.production) console.log('Service worker running.');
            this.serviceSubscriptions.push(interval(15 * 1000).subscribe(() => this.swUpdate.checkForUpdate()));
            this.serviceSubscriptions.push(
                this.swUpdate.versionUpdates.subscribe(evt => {
                    if(!environment.production) console.log(evt);
                    if (evt.type === 'VERSION_READY') {
                        this.$isAnyNewUpdateAvailable.next(true);
                    }
                }),
            );
            this.serviceSubscriptions.push(
                this.swUpdate.unrecoverable.subscribe(evt => {
                    if(!environment.production) console.log('App is in unrecoverable state. Reloading to avoid chunk load issue.');
                    window.location.reload();
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this.serviceSubscriptions?.forEach(x => x?.unsubscribe());
    }

}
