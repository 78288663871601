import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequiredComponent } from './components/required/required.component';
import { AppccRegisterFieldValueFormComponent } from '../modules/appcc/components/appcc-register-field-value-form/appcc-register-field-value-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { AppccRegisterUserFormComponent } from '../modules/appcc/components/appcc-register-user-form/appcc-register-user-form.component';
import { DropdownModule } from 'primeng/dropdown';
import { TraceabilityComponent } from './components/traceability/traceability.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProviderFormComponent } from '../modules/contacts/components/provider-form/provider-form.component';
import { TravoltaConfusedComponent } from './components/travolta-confused/travolta-confused.component';
import { RouterModule } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { InputNumberModule } from 'primeng/inputnumber';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { PrettyDOPipe } from './pipes/pretty-do.pipe';
import { VarietyComponent } from './components/variety/variety.component';
import { VesselFilterPipe } from './pipes/vessel-filter.pipe';
import { SuitabilityComponent } from './components/suitability/suitability.component';
import { MessageUpdateComponent } from './components/message-update/message-update.component';
import { MessagesModule } from 'primeng/messages';
import { ElementUnitTypePipe } from './pipes/element-unit-type.pipe';
import { SaleShrinkageFormComponent } from '../modules/sale/components/sale-shrinkage-form/sale-shrinkage-form.component';
import { WinemakingVesselSummaryComponent } from '../modules/winemaking/components/winemaking-vessel-summary/winemaking-vessel-summary.component';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
@NgModule({
    declarations: [
        RequiredComponent,
        AppccRegisterFieldValueFormComponent,
        AppccRegisterUserFormComponent,
        TraceabilityComponent,
        ProviderFormComponent,
        TravoltaConfusedComponent,
        HasPermissionDirective,
        PreventDoubleClickDirective,
        PrettyDOPipe,
        VesselFilterPipe,
        ElementUnitTypePipe,
        VarietyComponent,
        SuitabilityComponent,
        MessageUpdateComponent,
        SaleShrinkageFormComponent,
        WinemakingVesselSummaryComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InputTextModule,
        RadioButtonModule,
        CheckboxModule,
        CalendarModule,
        InputTextareaModule,
        TooltipModule,
        DropdownModule,
        ProgressSpinnerModule,
        RouterModule,
        DialogModule,
        TableModule,
        InputNumberModule,
        MessagesModule,
        InputGroupModule,
        InputGroupAddonModule
    ],
    exports: [
        RequiredComponent,
        AppccRegisterFieldValueFormComponent,
        AppccRegisterUserFormComponent,
        TraceabilityComponent,
        ProviderFormComponent,
        TravoltaConfusedComponent,
        HasPermissionDirective,
        PreventDoubleClickDirective,
        PrettyDOPipe,
        VesselFilterPipe,
        ElementUnitTypePipe,
        VarietyComponent,
        SuitabilityComponent,
        MessageUpdateComponent,
        SaleShrinkageFormComponent,
        WinemakingVesselSummaryComponent
    ]
})
export class SharedModule { }
