import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Language } from '../models/language';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

    constructor(
        private httpClient: HttpClient,
    ) { }

  index(): Observable<Language[]>{
    return this.httpClient.get<Language[]>(`${environment.apiUrl}/languages`).pipe(
        map(res => {
            return res;
        }),
        catchError(error => {
            return throwError(() => error);
        })
    );
  }
}
